<template>
  <div
    class="flex w-full h-[172px] md:h-[212px] justify-center items-center bg-secondary-light-blue"
  >
    <div v-if="isFullBowls" class="text-brand-navy text-right">
      <h2 class="font-bold">Full Bowls</h2>
      <h2 class="font-extrabold">Club</h2>
    </div>
    <img
      :alt="subscriptionType"
      src="@/assets/cuddly-club.png"
      class="max-w-[234.51px] max-h-[130px] h-full"
      v-else
    />
  </div>
  <div class="flex flex-col p-5">
    <h4 class="w-full text-brand-navy font-extrabold leading-9 mb-2.5">
      Your subscription has been paused.
    </h4>
    <p
      v-if="this.subscription.method === 'stripe' || this.subscription.method === 'stripeToken'"
      class="body-text-1 text-gray-700"
    >
      Your subscription has been paused for {{ months }} {{ pausedMonths }}.
      Your next billing date will be {{ pausedDate }}. If you change your mind,
      you can always donate again through the dashboard or
      <a
        :href="clubUrl"
        rel="noreferrer"
        target="_blank"
        class="underline underline-offset-4"
      >
        {{ clubUrlPlaceholder }}
      </a>
    </p>
    <p v-else class="subtitle-1 mb-10 text-gray-700">
      If you change your mind, you can always donate again through the dashboard
      or
      <a
        :href="clubUrl"
        rel="noreferrer"
        target="_blank"
        class="underline underline-offset-4"
      >
        {{ clubUrlPlaceholder }}
      </a>
    </p>
    <div
      class="w-full absolute left-0 right-0 bottom-28 md:bottom-10 px-5 md:px-[26px]"
    >
      <BaseButton
        id="back-to-dashboard"
        @click="handleBack"
        class="py-[14px] px-[66px] md:px-[33px] lg:px-[66px] w-full tracking-2"
      >
        Done
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import date from '@/mixins/date'

export default {
  name: 'SubscriptionCancelPaused',
  mixins: [date],
  components: { BaseButton },
  props: {
    subscription: {
      type: Object,
      default: () => {}
    },
    subscriptionType: {
      type: String,
      default: ''
    },
    months: {
      type: Number,
      default: 0
    },
    resumesAt: {
      type: Number,
      default: 0
    },
    isFullBowls: {
      type: Boolean,
      default: false
    },
    clubUrl: {
      type: String,
      default: ''
    },
    clubUrlPlaceholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cuddlyClubUrl: `${process.env.VUE_APP_CUDDLY_URL}/club`,
      fullBowlsUrl: `${process.env.VUE_APP_CUDDLY_URL}/fullbowls`
    }
  },
  methods: {
    handleBack() {
      this.emitter.emit('subscriptions-refresh', { reload: true })
    }
  },
  computed: {
    pausedDate() {
      const resumesAt =
        this.resumesAt > 0
          ? new Date(this.resumesAt * 1000)
          : this.subscription.subscription_start
      const monthAmount = this.resumesAt > 0 ? 0 : this.months
      const nextDate = this.setDate(resumesAt, {
        unit: 'month',
        amount: monthAmount
      })

      return this.formatDate(nextDate, 'MMM DD, YYYY')
    },
    pausedMonths() {
      return this.months === 1 ? 'month' : 'months'
    }
  }
}
</script>
