<template>
  <div
    class="min-h-screen md:w-full absolute md:fixed top-16 md:top-0 pt-3 md:pt-4 md:left-0 md:right-0 md:bg-black md:bg-opacity-20 z-50"
  >
    <div
      class="relative min-h-screen md:min-h-[693px] w-full md:max-w-[390px] bg-white flex flex-col lg:flex-row md:top-16 md:shadow-2 md:rounded-2xl md:mx-auto overflow-hidden animate-fade-up"
    >
      <div class="absolute top-0 md:right-0 p-5 pt-2.5 md:pr-5">
        <BaseIcon
          name="arrow-left"
          class="md:hidden w-6 h-6 text-brand-navy cursor-pointer"
          @click="handleDismiss"
          v-if="!reactivated || failed"
        />
        <BaseIcon
          name="close"
          class="hidden md:block h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleDismiss"
        />
      </div>
      <div class="flex flex-col w-full h-full items-start justify-center">
        <SubscriptionReactivateReactivated
          v-if="reactivated"
          :subscription-type="subscriptionType"
          :is-full-bowls="isFullBowls"
        />
        <SubscriptionReactivateFailed v-else-if="failed" :error="error" />
        <SubscriptionReactivateDefault :subscription="subscription" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionReactivateDefault from '@/components/Subscription/SubscriptionReactivate/SubscriptionReactivateDefault.vue'
import SubscriptionReactivateReactivated from '@/components/Subscription/SubscriptionReactivate/SubscriptionReactivateReactivated.vue'
import SubscriptionReactivateFailed from '@/components/Subscription/SubscriptionReactivate/SubscriptionReactivateFailed.vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'

export default {
  name: 'SubscriptionReactivate',
  components: {
    BaseIcon,
    SubscriptionReactivateFailed,
    SubscriptionReactivateReactivated,
    SubscriptionReactivateDefault
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reactivated: false,
      failed: false,
      error: 'Something went wrong. Please try again.'
    }
  },
  created() {
    this.emitter.on('subscription-reactivate_failed', ({ error }) => {
      this.reactivated = false
      this.failed = true
      this.error = error || this.error
    })
    this.emitter.on(
      'subscription-reactivate_reactivated',
      ({ reactivated }) => {
        this.reactivated = reactivated
      }
    )
  },
  methods: {
    handleDismiss() {
      this.emitter.emit('subscriptions-refresh', { reload: this.reactivated })
      this.$store.commit('setIsSubscriptionReactivateOpen', false)
      this.$emit('dismiss')
    }
  },
  watch: {
    '$store.state.isSubscriptionReactivateOpen': function (value) {
      if (!value) {
        this.handleDismiss()
      }
    }
  },
  computed: {
    showStripeAmountChangeOption() {
      return this.subscription.method === 'stripe' || this.subscription.method === 'stripeToken'
    },
    amount() {
      const amount =
        this.subscription?.amount || this.subscription?.future_amount || 0

      return this.formatCurrency(Math.floor(amount), { decimals: false })
    },
    subscriptionType() {
      return this.subscription.type === 'fullbowls'
        ? 'Full Bowls Club'
        : 'CUDDLY Club'
    },
    isFullBowls() {
      return this.subscription.type === 'fullbowls'
    },
    imageSrc() {
      const images = require.context('@/assets', true)
      const image =
        this.subscription.type === 'fullbowls'
          ? 'full-bowls-club-subscriptions-state.png'
          : 'cuddly-club-mutt-scouts.png'

      return images(`./${image}`)
    }
  }
}
</script>

<style scoped>
.subscription-reactivate-enter-active {
  animation: fadeUp 1s ease-in;
}

.subscription-reactivate-leave-active {
  animation: fadeDown 1s ease-out;
}
@keyframes fadeUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
</style>
